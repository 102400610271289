import styled from "styled-components";

export const Container = styled.section`

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 4rem 0;

.get-in-touch-title{
    span{
        font-size: 1rem;
    }
}

.contact-form{
    width: 70%;
    margin: 0 auto;
    background: rgb(var(--rgb-white));
    padding: 2rem;
    border-radius: 20px;
    margin-top: 2rem;
    box-shadow: 3px 3px 5px #bfbfbf, -3px -3px 5px #ffffff;

    button{
        width: 7rem;
        border: none;
        background: rgb(var(--rgb-theme));
        padding: 1rem;
        border-radius: 25px;
        color: rgb(var(--rgb-white));
        font-weight: 600;
    }
}

.input-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 30px;
    padding-top: 2rem;
}

.input-field{
    display: flex;
    flex-direction: column;
    width: 100%;

    p{
        font-size: 14px;
        color: red;
        margin-top: 4px;
    }

    label{
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    input{
        height: 1.5rem;
        padding: 1rem;
        border-radius: 10px;
        background: rgb(var(--rgb-smoke-white));
        border: none;
        &:focus{
            outline: 0;
        }
    }
}

.text-field{
    display: flex;
    flex-direction: column;
    padding: 2rem 0;

p{
    font-size: 14px;
    color: red;
    margin-top: 4px;
}

label{
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
    textarea{
        height: 3rem;
        padding: 2rem;
        border-radius: 10px;
        background: rgb(var(--rgb-smoke-white));
        border: none;
        &:focus{
            outline: 0;
        }
    }
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 35%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.popup-inner {
  text-align: center;
}

.popup button {
  margin-top: 10px;
  background: rgb(var(--rgb-theme));
  color: rgb(var(--rgb-white));
  border: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
}


@media(max-width: 809px){
    .input-field{

        label{
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }

        input{
            height: 1rem;
        }
    }

    .text-field{
        label{
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }

        textarea{
            padding: 1rem;
        }
    }

.popup {
  width: 40%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
}

@media(max-width: 809px){
    .input-container{
        display: inline-block;
        width: 100%;

        .input-field{
            padding: 1rem 0;
        }
    }
}

@media(max-width: 500px){
    .contact-form{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .input-container{
        padding-top: 0;
    }

        .text-field{
            width: 100%;
        }

        button{
            width: 5.5rem;
            padding: 0.75rem;
        }
    }

.popup {
  width: 50%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
}

`