import ic_facebook from "../../assets/icons/ic_facebook.png"
import ic_linkedin from "../../assets/icons/ic_linkedin.png"
import ic_twitter from "../../assets/icons/ic_twitter.png"
import img_white_email from "../../assets/images/img_white_email.png"
import img_white_phone from "../../assets/images/img_white_phone.png"

export const socialMediaLinks = [
    {
        icon: ic_facebook,
    },
    {
        icon: ic_linkedin,
    },
    {
        icon: ic_twitter,
    },
]

export const pathCategories = [
    {
        title: "Pages",
        paths: [
            {
                name: "Home",
            },
            {
                name: "Features",
            },
            {
                name: "About Us",
            },
            {
                name: "Pricing",
            }
        ]
    },
    {
        title: "About",
        paths: [
            {
                name: "Blog",
            },
            {
                name: "Contact",
            },
            {
                name: "Resources",
            },
        ]
    },
    {
        title: "Privacy",
        paths: [
            {
                name: "Terms",
            },
            {
                name: "Cookies",
            },
            {
                name: "Privacy Policy",
            },
        ]
    },
]

export const contactDetails =[
    {
        icon: img_white_email,
        type: "email",
        text:"reachus@ideassion.com",
    },
    {
        icon: img_white_phone,
        type: "phone",
        text:"+91-9600466139 / +1 (860)879-9066",
    },
]

export const address =[
    {
        country: "India",
        address: "190-192, 2nd Floor, Hameed Complex, Anna Salai, Chennai 600006",
    },
    {
        country: "USA",
        address: "600 N Broad Street Suite 5 #3229 Middletown, DE 19709",
    },
]