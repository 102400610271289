import styled from "styled-components";

export const Container = styled.section`
  padding: 96px 0;

  .content {
    width: 100%;
    height: 55vh;
    margin-top: 64px;
    display: flex;
    overflow-x: hidden;
    background: rgb(var(--rgb-theme));
    border-radius: 20px;
    border: 1px solid #e8e8e8;
  }

  .content-left {
    width: 100%;
    padding: 32px 16% 32px 72px;
    background-color: rgb(var(--rgb-white));

    .verbiage-wrapper {

h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  color: rgb(var(--rgb-dark-grey));
}
    }
  }

  .integrated-apps {
    width: 53%;
    height: 100%;
    object-position: -15rem;
    object-fit: cover;
  }

  .commerce-hub {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(var(--rgb-wood-brown));
    color: rgb(var(--rgb-white));
    border-radius: 20px;
    margin-top: 32px;
    padding: 2rem 0 0;

    .verbiage {
      text-align: center;
      width: 28%;
      padding-bottom: 4rem;
      h2 {
        font-size: 32px;
        margin-bottom: 10px;
      }

      p {
        font-size: 18px;
      }
    }

    img {
      width: 35%;
      padding: 2rem 1rem 0;
      background-color: rgb(var(--rgb-nurse-grey));
      border-radius: 20px 20px 0 0;
    }
  }

  @media (max-width: 1200px) {
    .content {
      height: 58vh;
    }
    .content-left {
      padding: 40px 20% 40px 24px;
    }

    .integrated-apps {
      width: 50%;
    }
  }

  @media (max-width: 809px) {
    padding: 64px 16px;
    margin: 0;

    .content {
      flex-direction: column;
    height: max-content;
    }

    .content-left {
      padding: 40px 24px;

      .verbiage-wrapper {
        width: 288px;

        h2 {
          font-size: 24px;
          font-weight: 700;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .integrated-apps{
    width: 100%;
    height: 60vw;
    object-fit: cover;
    object-position: center;
  }

  .commerce-hub{
    padding: 0;
    height: 436px;
    justify-content: flex-start;
    position: relative;
    .verbiage{
      width: 100%;
      padding-top: 2rem;
      padding-bottom: 0;

      h2{
        font-size: 24px;
      }

      p{
        font-size: 16px;
      }
    }

    img{
      width: 250px;
      height: 200px;
      border-radius: 20px;
      padding: 8px;
      position: absolute;
      top: 10rem;
    }
  }

  }
`;
