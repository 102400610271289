export const headerNavItems = [
    // {
    //     name: "Home",
    //     path: "/",
    // },
    // {
    //     name: "About Us",
    //     path: "/",
    // },
    // {
    //     name: "Our Services",
    //     path: "/",
    // },
    // {
    //     name: "Blog",
    //     path: "/",
    // },
    // {
    //     name: "Contact",
    //     path: "/",
    // },
    // {
    //     name: "FAQ",
    //     path: "/",
    // },
]