import { Container } from "./styles";
import Title from "../../../components/title";
import { featuresData } from "../constants";
import { Slide } from "react-awesome-reveal";
import { useResize } from "../../../hooks/useResize";

const Features = () => {

  const {screenWidth} = useResize()


    const renderFeatures =({image, key, title}, index)=>(
      <Slide direction={screenWidth > 809 ? index % 2 === 0 ? "left" : "right" : "right"} triggerOnce>
        <li key={title} className="feature-item">
          <img src={image} alt={title}/>
          <div className="card-content">
          <span>{key}: </span>
          <h1>{title}</h1>
          {/* <span className="card-link">Explore Solutions</span> */}
          </div>
        </li>
        </Slide>
    )

  return (
    <Container className="indentation">
      <Title
        title={"PRODUCT FEATURES"}
        context={"All The Features You Need In One Place"}
        description={
          "We offer packages consisting of various complete services, choose according to your needs."
        }
        wrapperClass={"title-class"}
      />
      {/* <Slide direction="left" triggerOnce> */}
      <div className="content-wrapper">
      <ul className="features-wrapper">
        {featuresData?.map(renderFeatures)}
      </ul>
      </div>
      {/* </Slide> */}
    </Container>
  );
};

export default Features;
