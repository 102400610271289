import styled from "styled-components";

export const Container = styled.section`
/* width: 100%; */
display: flex;
flex-direction: column;
gap: 2rem;
background: rgb(var(--rgb-white));
padding: 2rem;
border-radius: 2rem;
margin-top: 2rem;
.statement-title{
    align-items: flex-start;
    text-align: left;
    margin: 0;
}

p{
    line-height: 2;
    font-size: 1.2rem;
}

@media(max-width: 700px){
    background: transparent;
}

@media(max-width: 500px){
padding: 1rem;
margin-top: 0;

p{
    font-size: 1rem;
}
}
`