import styled from "styled-components";

export const Container = styled.article`

.company-philosopy{
    background: rgb(var(--rgb-smoke-white));
    padding: 3rem 0;
}

.motto-wrapper{
    display: flex;
    gap: 4rem;
}

@media(max-width: 700px){
.motto-wrapper{
    flex-direction: column;
    gap: 0;
}
}

@media(max-width: 500px){
    .company-philosopy{
    padding: 1.5rem 0;}
}
`