import styled from "styled-components"

export const Container = styled.section`
    position: relative;
  
  .open-modal-button {
    cursor: pointer;
  }
  
  .overlay {
    position: fixed;
    top: 6rem;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    background-color: red;
  }
  
  .overlay.open {
    width: 100%;
    height: 60%;
    overflow-y: scroll;
  }
  `