import React from "react";
import { Container, PrevArrow, NextArrow } from "./styles";
import { benefitsData } from "../constants";
import Title from "../../../components/title";
import { Slide } from "react-awesome-reveal";
import Carousal from "../../../components/carousel";
import img_theme_arrow from "../../../assets/images/img_theme_arrow.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useResize } from "../../../hooks/useResize";

const Benefits = () => {
  const { screenWidth } = useResize();

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <PrevArrow className={className} onClick={onClick}>
        <img src={img_theme_arrow} />
      </PrevArrow>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <NextArrow className={className} onClick={onClick}>
        <img src={img_theme_arrow} />
      </NextArrow>
    );
  };

  const settings = {
    className: "center",
    centerMode: false,
    dots: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 809,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          arrow: false
        },
      },
    ],
  };

  const renderBenefits = ({ icon, title, subTitle, description }) => (
    <li key={title} className="benefit-item">
      <img src={icon} alt={title} />
      <h1>{title}</h1>
      <h3>{subTitle}</h3>
      <p>{description}</p>
    </li>
  );

  return (
    <Container>
      <Title
        title="BENEFITS"
        description="Build lasting relationships with our complete customer."
        wrapperClass={"title-class"}
      />
      <Slide direction="up" triggerOnce>
          {screenWidth > 700 ? (
        <ul className="indentation benefits-wrapper">
            <Carousal customSetting={settings}>
              {benefitsData?.map(renderBenefits)}
            </Carousal>
        </ul>
          ) : (
            <ul className="indentation benefits-wrapper">
              {benefitsData?.map(renderBenefits)}
            </ul>
          )}
      </Slide>
    </Container>
  );
};

export default Benefits;
