import styled from "styled-components";

export const Container = styled.section`
  padding: 78px 0 144px;

  .verbiage-section {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 72px;

    .title-wrapper {
      width: 52%;
      font-size: 72px;
      font-weight: 700;
      margin: 0;

      span {
        color: rgb(var(--rgb-theme));
        font-style: italic;
        font-weight: 600;
      }
    }

    .demo-wrapper {
      width: 48%;
      display: flex;
      flex-direction: column;
      gap: 32px;

      img{
        width: 90%;
        /* height: 10%; */
      }

      p {
        font-size: 19px;
        color: rgb(var(--rgb-dark-grey));

        img{
          /* width: 20%; */
          max-width: clamp(50px, 21%, 150px);
          position: relative;
          top: 8px;
        }
      }

      .clients-section{
        display: flex;
        flex-direction: column;
        gap: 32px;
      }

      .button-wrapper {
        display: flex;
        gap: 16px;
        padding: 1rem 0 2rem;

        button {
          font-size: 16px;
          font-weight: 500;
          background: transparent;
          padding: 12px 24px;
          border-radius: 100px;
        }

        .demo-button {
          border: 1px solid rgb(var(--rgb-theme));
          color: rgb(var(--rgb-black));
        }

        .trial-button {
          border: none;
          color: rgb(var(--rgb-white));
          background: rgb(var(--rgb-theme));
        }
      }

      .clients-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        img {
          width: 160px;
          height: 64px;
        }

        .verbiage-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        h3 {
          font-size: 24px;
          font-weight: 700;
          color: rgb(var(--rgb-theme));
        }

        span {
          font-size: 18px;
          font-weight: 500;
          color: rgb(var(--rgb-theme));
        }
      }
    }
  }

  .brands-wrapper {
    mask-image: linear-gradient(
      to right,
      rgba(var(--rgb-black), var(--alpha-zero)) 0%,
      rgb(var(--rgb-black)) 12.5%,
      rgb(var(--rgb-black)) 87.5%,
      rgba(var(--rgb-black), var(--alpha-zero)) 100%
    );
  }

  .brand {
    width: 100%;
  }

@media (max-width: 1300px) {
  .verbiage-section {
    .demo-wrapper {
      p{
        img{
        top: 6px;
        }
      }
    }
  }
}

  @media (max-width: 1200px) {
    padding: 48px 0;
    .verbiage-section {
      flex-direction: column;
      align-items: flex-start;

      .title-wrapper {
        width: 100%;
        font-size: 53px;
      }

      .demo-wrapper {
        width: 45%;
        padding-top: 24px;

        p{
          img{
          top: 6px;
          }
        }

        .button-wrapper {
          padding: 0;
        }

        .clients-wrapper {
          padding-top: 16px;
        }
      }
    }
  }

  @media(max-width: 1100px){
  .verbiage-section {

    .demo-wrapper {
      p{
        img{
          max-width: clamp(50px, 25%, 100px);
          /* top: 6px; */
        }
      }
    }

  }
  }

@media (max-width: 809px) {
  padding: 2rem 0;
  .verbiage-section {
    gap: 32px;
    padding-bottom: 0;

    .title-wrapper {
      font-size: 32px;
    }

    .demo-wrapper {
      width: 100%;
      padding-top: 0;

      img{
        width: 50%;
      }

      p {
        font-size: 16px;

        img{
          top: 7px;
        }
      }

      .clients-section {
        flex-direction: column-reverse;

        .clients-wrapper {
          padding-top: 0;
          img {
            width: 112px;
            height: 48px;
          }

          h3 {
            font-size: 18px;
          }

          span {
            font-size: 14px;
          }
        }

        .button-wrapper {
          flex-direction: row-reverse;
          justify-content: flex-end;
          padding-top: 24px;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .verbiage-section {
    .demo-wrapper {
      p{
        /* img{
          width: 14%;
          top: 6px;
        } */
      }
    }
  }
}

@media (max-width: 650px) {
  .verbiage-section {
    .demo-wrapper {
      img{
        width: 70%;
      }

      p{
        /* img{
          
        } */
      }
    }
  }
}
`;
