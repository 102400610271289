import { Container } from "./styles";
import Title from "../../../components/title";
import { Slide } from "react-awesome-reveal";

const Mission = () => {
  return (
    <Container>
      <Title context={"Mission"} wrapperClass={"mission-title"} />
      <Slide direction="up" triggerOnce>
      <p className="mission-content">
        Our mission is to empower organizations to navigate the volatile,
        uncertain, complex, and ambiguous (VUCA) business landscape by fostering
        a culture of Governance, Risk, and Compliance (GRC).
      </p>
      </Slide>
    </Container>
  );
};

export default Mission;
