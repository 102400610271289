import styled from "styled-components";

export const Container = styled.section`
  padding: 120px 0;
  background: rgb(var(--rgb-smoke-white));

  p {
    margin: 0;
  }

  .title-class {
    max-width: 50%;
    margin: 0;
    padding: 0 140px;
    text-align: left;
    align-items: flex-start;
  }

  /* .feedbacks-wrapper{
        display: flex;
        gap: 28px;
        width: 100%;
        overflow-x: scroll;
    } */

  .feedback-item {
    max-width: 80%;
    background: rgb(var(--rgb-white));
    padding: 32px;
    border-radius: 20px;
    .feedback-info {
      display: flex;
      align-items: center;
      margin-top: 32px;
      gap: 12px;
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }

    .feedback {
      font-size: 18px;
    }

    .author-details {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .author-name {
      font-size: 16px;
      font-weight: 500;
      color: rgb(var(--rgb-black-pearl));
    }

    .firm-name {
      font-size: 14px;
      color: rgb(var(--rgb-chambray));
    }
  }

  .carousel-one-wrapper {
    margin-top: 5rem;
  }

  .carousel-two-wrapper {
    margin-top: 2rem;
  }
`;
