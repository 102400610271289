import styled from "styled-components";

export const Container = styled.section`
  background: rgb(var(--rgb-white));
  padding: 4rem 0;

  .indentation {
    height: 60vh;
    display: flex;
    justify-content: space-between;
  }

  .title-class {
    width: 60%;
    align-items: flex-start;
    text-align: left;
    max-width: unset;
    margin: 0;
  }

  ul {
    width: 40%;
    height: 100%;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar for Webkit browsers */
}

    & > li:not(:last-child) {
      margin-bottom: 10px;
    }

    li {
      background: rgb(var(--rgb-white));
      padding: 2rem;
      border: 2px solid rgba(var(--rgb-black), var(--alpha-two));
      border-radius: 20px;
      p{
        font-size: 20px;

        span{
          color: rgb(var(--rgb-theme));
          font-weight: 700;
        }
      }
    }
  }

@media(max-width: 809px){
  .indentation{
    height: 50vh;
    /* flex-direction: column; */

.title-class {
width: unset;
padding-bottom: 2rem;
}

ul {
width: 50%;
margin: 0 auto;
padding-top: 0;
}

  }
}

@media(max-width: 750px){
  padding: 2rem 0;
  .indentation{
    height: 80vh;
    flex-direction: column;

ul {
width: 80%;
margin: 0 auto;
padding-top: 2rem;
}

  }
}
`;
