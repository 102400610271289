import { Container } from "./styles";
import Title from "../../../components/title";
import { Slide } from "react-awesome-reveal";

const ProductStatement = () => {
  return (
    <Container className="">
      <Slide direction="up" triggerOnce>
        <Title context={"Product Statement"} wrapperClass={"statement-title"} />
        <p>
          Our product offers unmatched professionalism and efficiency in
          Governance, Risk, and Compliance (GRC) solutions. Built on
          cutting-edge technology and streamlined design, it enables seamless
          navigation of regulatory complexities while ensuring optimal
          performance and reliability. With a sharp focus on simplicity and
          effectiveness, our product equips organizations to achieve compliance
          effortlessly and drive continuous growth.
        </p>
      </Slide>
    </Container>
  );
};

export default ProductStatement;
