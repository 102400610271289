import { Container } from "./styles";
import ic_citadelvue_white_logo from "../../assets/icons/ic_citadelvue_white_logo.svg";
import ic_copyright from "../../assets/icons/ic_copyright.png";
import { socialMediaLinks, pathCategories, contactDetails, address } from "./constants";

const Footer = () => {
  const renderLinks = ({ icon }) => (
    <li key={icon} className="social-media-item">
      <img src={icon} alt="social media icons" />
    </li>
  );

  const renderPaths = ({ name }) => <li className="path-name">{name}</li>;

  const renderCategories = ({ title, paths }) => (
    <li key={title} className="category-item">
      <span className="category-title">{title}</span>
      <ul className="links-wrapper">{paths?.map(renderPaths)}</ul>
    </li>
  );

  const renderContactDetails=({icon, text, type})=>(
    <li key={icon} className="link-item">
      <img src={icon} alt={type} />
      <p>{text}</p>
    </li>
  )

  const renderAddress=({country, address})=>(
    <li className="hq-address">
      <p><u>{country}:</u></p>
      <p>{address}</p>
      {/* <p>Anna Salai, Chennai 600006</p> */}
    </li>
  )

  return (
    <Container>
      <div className="footer-content-wrapper">
      <img
        src={ic_citadelvue_white_logo}
        className="footer-logo"
        alt="citadel vue logo"
      />
      <div className="contact-wrapper">
        <ul className="branch-address">
          {address?.map(renderAddress)}
        </ul>
        {/* <div className="contact-links"> */}
        <ul className="contact-detail">
          {contactDetails?.map(renderContactDetails)}
        </ul>
        </div>
        </div>
        <ul className="social-media">{socialMediaLinks?.map(renderLinks)}</ul>
      <div className="copyright-section">
        <img src={ic_copyright} alt="copy right icon" className="copy-right" />
        <p className="firm-name">
          {" "}
          2024, Powered by{" "}
          <a href="https://ideassion.com/" target="blank">
            Ideassion
          </a>{" "}
          x{" "}
          <a href="https://tritonlabs.in/" target="blank">
            Triton
          </a>
        </p>
      </div>
        {/* <u className="social-media-headline">Social media:</u>
          <ul className="social-media">{socialMediaLinks?.map(renderLinks)}</ul> */}
          {/* </div> */}
      {/* <img
        src={ic_citadelvue_white_logo}
        className="footer-logo"
        alt="citadel vue logo"
      />
      <div className="footer-links">
        <div className="footer-address">
          <div className="hq-address">
            <p><u>India:</u></p>
            <p>190-192, 2nd Floor, Hameed Complex,</p>
            <p>Anna Salai, Chennai 600006</p>
          </div>
          <div className="hq-address address-us">
            <p><u>USA:</u></p>
            <p>600 N Broad Street Suite 5 #3229 Middletown,</p>
            <p>DE 19709</p>
          </div>
          <ul className="social-media">{socialMediaLinks?.map(renderLinks)}</ul>
        </div>
        <ul className="path-categories">
          {pathCategories?.map(renderCategories)}
        </ul>
      </div>
      <div className="copyright-section">
        <img src={ic_copyright} alt="copy right icon" className="copy-right" />
        <p className="firm-name">
          {" "}
          2024, Powered by{" "}
          <a href="https://ideassion.com/" target="blank">
            Ideassion technology
          </a>{" "}
          x{" "}
          <a href="https://tritonlabs.in/" target="blank">
            Triton tech lab
          </a>
        </p>
      </div> */}
    </Container>
  );
};

export default Footer;
