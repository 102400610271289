import styled from "styled-components";

export const Container = styled.section`

max-width: 45vw;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 16px;
text-align: center;

h3{
    color: rgb(var(--rgb-theme));
}

h1{
    font-size: 52px;
}

span{
    font-size: 18px;
    color: rgb(var(--rgb-dark-grey));
}

@media(max-width: 1200px){
    max-width: unset;

h1{
    font-size: 45px;
}
}

@media(max-width: 809px){

max-width: 85vw;

h1{
    font-size: 40px;
}
}

@media(max-width: 600px){

max-width: 90vw;

h1{
    font-size: 30px;
}
}
`