import img_profitable from "../../assets/images/img_profitable.png";
import img_features from "../../assets/images/img_features.webp";
import AuditManagement from "../../assets/images/Audit Management.jpg";
import Compliancemanagement from "../../assets/images/Compliance management.jpg";
import Incidentmanagement from "../../assets/images/Incident management.jpg";
import IssueManagement from "../../assets/images/Issue Management.jpg";
import RiskManagement from "../../assets/images/Risk Management.jpg";
import ReportingandAnalytics from "../../assets/images/Reporting and Analytics.jpg";
import img_feedback from "../../assets/images/img_feedback.webp";
import img_trial_brand from "../../assets/images/img_trial_brand.png";
import img_trial_brand_two from "../../assets/images/img_trial_brand_two.png";
import Audit from "../../assets/icons/Audit.svg";
import carbon_collaborate from "../../assets/icons/carbon_collaborate.svg";
import Centralized from "../../assets/icons/Centralized.svg";
import Compliance from "../../assets/icons/Compliance.svg";
import decisionMaking from "../../assets/icons/Data-driven decision-making.svg";
import Improvement from "../../assets/icons/Improvement.svg";
import lucide_scaling from "../../assets/icons/lucide_scaling.svg";
import Riskmanagement from "../../assets/icons/Risk management.svg";
import Supervise from "../../assets/icons/Supervise.svg";


export const trialBrands = [
  img_trial_brand,
  img_trial_brand_two,
  img_trial_brand,
  img_trial_brand_two,
  img_trial_brand,
  img_trial_brand_two,
  img_trial_brand,
  img_trial_brand_two,
  img_trial_brand,
  img_trial_brand_two,
];

export const benefitsData = [
  {
    icon: Centralized,
    // title: "$30",
    subTitle: "Centralized",
    description:
      "Centralized management of governance, risk, and compliance activities",
  },
  {
    icon: Supervise,
    // title: "$30",
    subTitle: "Supervise",
    description:
      "Real-time monitoring of risks and compliance status",
  },
  {
    icon: Compliance,
    // title: "$30",
    subTitle: "Compliance",
    description:
      "Assurance of compliance with laws, regulations, and industry standards",
  },
  {
    icon: Riskmanagement,
    // title: "$30",
    subTitle: "Risk management",
    description:
      "Proactive risk management to mitigate threats and vulnerabilities",
  },
  {
    icon: decisionMaking,
    // title: "$30",
    subTitle: "Data-driven decision-making",
    description:
      "Data-driven decision-making through robust reporting and analytics",
  },
  {
    icon: carbon_collaborate,
    // title: "$30",
    subTitle: "Collaborate",
    description:
      "Improved collaboration among stakeholders",
  },
  {
    icon: lucide_scaling,
    // title: "$30",
    subTitle: "Scaling",
    description:
      "Scalability to accommodate organizational growth and regulatory change ",
  },
  {
    icon: Audit,
    // title: "$30",
    subTitle: "Audit",
    description:
      "Comprehensive audit trails for transparency and accountability ",
  },
  {
    icon: Improvement,
    // title: "$30",
    subTitle: "Improvement",
    description:
      "Facilitation of continuous improvement initiatives ",
  },
];

export const featuresData = [
  {
    image: RiskManagement,
    key: "Risk Management",
    title: `Identification, assessment, mitigation, and monitoring of risks across the organization.`,
  },
  {
    image: Compliancemanagement,
    key: "Compliance Management",
    title: `A framework that helps in tracking and adherence to regulatory requirements, industry standards, and internal policies.`,
  },
  {
    image: AuditManagement,
    key: "Audit Management",
    title: `Planning, execution, and reporting of internal and external audits.`,
  },
  {
    image: IssueManagement,
    key: "Issue management",
    title: `Identification, assessment, Resolution, and monitoring of Issue across the organization.`,
  },
  {
    image: Incidentmanagement,
    key: "Incident Management",
    title: `Reporting, investigation, and resolution of incidents and breaches.`,
  },
  {
    image: ReportingandAnalytics,
    key: "Reporting and Analytics",
    title: `Generation of comprehensive reports, dashboards, and insights to support strategic decision-making.`,
  },
];

export const feedbackData = [
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
  {
    image: img_feedback,
    name: "Eleanor Pena",
    firm: "Koala Co.",
    feedback:
      "If you are a business owner, and you don't have Marketplus in your toolkit just yet, I highly recommend that you check it out.",
  },
];
