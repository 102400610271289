import { Container } from "./styles"
import { useRef } from "react"
import Trial from "./trial"
import Benefits from "./benefits"
import Features from "./features"
import Feedbacks from "./feedbacks"
import AdditionalFeatures from "./additional-features"
import NewsLetter from "./news-letter"
import Mission from "./mission"
import Vision from "./vision"
import ProductStatement from "./product-statement"
import AboutUs from "./about-us"
import WhyUs from "./why-us"
import GetInTouch from "./get-in-touch"

const Home = () => {
  const bottomRef = useRef(null);

  // const scrollToBottom = () => {
  //     bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // };

  return (
    <Container>
    <Trial/>
    <Benefits/>
    <Features/>
    <div className="company-philosopy">
    <div className="indentation">
    <div className="motto-wrapper">
    <Vision/>
    <Mission/>
    </div>
    <ProductStatement/>
    </div>
    </div>
    <WhyUs/>
    <AboutUs/>
    <GetInTouch />
    </Container>
  )
}

export default Home
