import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Carousal({ children, customSetting }) {
  return (
    <div>
      <Slider {...customSetting}>
        {children}
      </Slider>
    </div>
  );
}

export default Carousal;
