// import React from "react";
import Header from "./components/header";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import Home from "./pages/home";
import Footer from "./components/footer";
function App() {
  const Layout = ({ children }) => {
    return (
      <div className="home-container-bg">
        <Header />
        {children}
        <Footer />
      </div>
    );
  };

  // const Loader = () => <div className="loader"></div>;

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Suspense>
            <Home />
          </Suspense>
        </Layout>
      ),
    },
  ]);
  return (
    <section className="App">
        <RouterProvider router={router} />
    </section>
  );
}

export default App;
