import { useState, useEffect } from "react";
import { Container } from "./styles";
import Title from "../../../components/title";
import { Slide } from "react-awesome-reveal";

const GetInTouch = () => {

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [inputData, setInputData] = useState({
    name:"",
    email:"",
    phone:"",
    subject:"",
    message:"",
  });
  const [inputError, setInputError] = useState({
    errName:"",
    errEmail:"",
    errPhone:"",
    errSubject:"",
    errMessage:"",
  });

  const {name, email, phone, subject, message} = inputData
  const {errName, errEmail, errPhone, errSubject, errMessage} = inputError
  
  useEffect(() => {
    if (isSubmitClicked) {
      let errors = {};
      let isValid = true;
  
      if (name.trim() === "") {
        errors.errName = "Name is required";
        isValid = false;
      }

      else errors.errName = "";
  
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        errors.errEmail = !email.trim() ? "Email is required" : "Invalid email address";
        isValid = false;
      }

      else errors.errEmail = "";
  
      if (phone.trim()?.length < 10) {
        errors.errPhone = !phone.trim() ? "Phone is required" : "Invalid phone number";
        isValid = false;
      }

      else errors.errPhone = "";
  
      if (subject.trim() === "") {
        errors.errSubject = "Subject is required";
        isValid = false;
      }

      else errors.errSubject = "";
  
      if (message.trim() === "") {
        errors.errMessage = "Message is required";
        isValid = false;
      }

      else errors.errMessage = "";
  
      setInputError(errors);

      if (isValid) {
        setShowPopup(true);
        setInputData({
          name:"",
          email:"",
          phone:"",
          subject:"",
          message:"",
        })
      }

      setIsSubmitClicked(false);

    }
  }, [isSubmitClicked, name, email, phone, subject, message]);
  

  const onClickSubmit = () => {
    setIsSubmitClicked(true)
  }
  
  const onChangeData = (e) => {
    const { name, value } = e.target;
  
    if(name === "phone"){
      if(value.length <= 10) setInputData((prev) => ({ ...prev, [name]: value }))
    }
    else setInputData((prev) => ({ ...prev, [name]: value }));
  }

  return (
    <Container className="bottom-element">
    <Slide direction="up" triggerOnce style={{width: "100%"}}>
      <Title
        context={"Get In Touch"}
        description={`Have questions or interested in Citadelvue ? We’re here to help! Contact us today.`}
        wrapperClass={"get-in-touch-title"}
      />
      <div className="indentation contact-form">
      <div className="input-container">
        <div className="input-field">
          <label>Full Name</label>
          <input type="text" value={name} name="name" onChange={onChangeData} />
          {errName && <p>{errName}</p>}
        </div>
        <div className="input-field">
          <label>Email address</label>
          <input type="email" value={email} name="email" onChange={onChangeData} />
          {errEmail && <p>{errEmail}</p>}
        </div>
        <div className="input-field">
          <label>Phone</label>
          <input type="number" value={phone} name="phone" onChange={onChangeData} />
          {errPhone && <p>{errPhone}</p>}
        </div>
        <div className="input-field">
          <label>Subject</label>
          <input type="text" value={subject} name="subject" onChange={onChangeData} />
          {errSubject && <p>{errSubject}</p>}
        </div>
      </div>
      <div className="text-field">
        <label>Message:</label>
        <textarea value={message} name="message" onChange={onChangeData}></textarea>
          {errMessage && <p>{errMessage}</p>}
      </div>
      <button onClick={onClickSubmit}>Submit</button>
      </div>
      </Slide>
      {showPopup && (
        <div className="popup-container">
          <div className="popup">
            <div className="popup-inner">
              <h2>Thank you!</h2>
              <p>Your form has been submitted successfully.</p>
              <button onClick={() => setShowPopup(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default GetInTouch;
