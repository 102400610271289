import styled from "styled-components";

export const Container = styled.section`
  padding: 96px 0;

  .title-class{
    span{
      font-size: 1rem;
    }
  }

  .features-wrapper {
    display: grid;
    flex: none;
    gap: 24px;
    grid-auto-rows: minmax(0, 1fr);
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%;
    padding-top: 48px;
  }

  .feature-item {
    width: 90%;
    display: flex;
    gap: 24px;
    border: 1px solid #e8e8e8;
    padding: 24px;
    border-radius: 20px;
    img {
      width: 213px;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }

    .card-content {
      align-items: flex-start;
      display: flex;
      flex: 1 0 0px;
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;
      overflow: visible;
      padding: 0;
      position: relative;

      span,h1 {
        font-size: 20px;
        font-weight: 400;
      }

      span {
        color: rgb(var(--rgb-theme));
        font-weight: 700;
      }

      .card-link {
        font-size: 18px;
        font-weight: 600;
        color: rgb(var(--rgb-nero-black));
        padding-bottom: 8px;
        border-bottom: 1px solid;
      }
    }
  }

  @media (max-width: 1200px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;

.features-wrapper {
  grid-auto-rows: unset;
}

    .feature-item {
      flex-direction: column;
      align-items: flex-start;

      img {
        width: 100%;
        max-height: 50%;
        object-fit: cover;
      }

      .card-content {
        h1 {
          width: 284px;
        }
      }
    }
  }

  @media (max-width: 809px) {
    padding: 4rem 0;
    max-width: 100%;

    .content-wrapper {
      overflow-x: auto;
      white-space: nowrap;
    }
    .features-wrapper {
      display: inline-block;

      .feature-item {
        display: inline-block;
        margin: 0 1rem;
        padding: 16px;

        img {
          height: 50vw;
        }

        .card-content {
          h1 {
            width: 70%;
            text-wrap: wrap;
          }
        }
      }
    }
  }
`;
