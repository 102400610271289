import styled from "styled-components";

export const Container = styled.section`

/* text-align: center; */
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background: rgb(var(--rgb-smoke-white));
padding: 96px 0;

.title-class{
    h3{
        font-size: 2rem;
    }

    span{
        font-size: 1rem;
    }
    }

.benefits-wrapper{
    /* width: 100%;
    display: flex;
    gap: 24px; */
    max-width: 90vw;
    padding-top: 48px;
}

.benefit-item{
    max-width: 80%;
    min-height: 155px;
    /* max-height: 300px; */
    /* width: 10rem; */
    /* margin-right: 1rem; */
    text-align: left;
    background: rgb(var(--rgb-ivory));
    padding: 36px;
    border-radius: 20px;
    img{
        width: 40px;
        height: 40px;
    }

    h1{
        font-size: 48px;
        font-weight: 700;
        color: rgb(var(--rgb-nero-black));
        margin: 0;
    }

    h3{
        color: rgb(var(--rgb-nero-black));
        margin: 1rem 0 2rem;
        font-size: 18px;
    }

    p{
        font-size: 16px;
    }
}

@media(max-width: 1100px){
    padding-top: 2rem;
}

@media(max-width: 700px){
    .title-class{
        padding-bottom: 2rem;
    }
    .benefit-item{
        max-width: unset;
        width: unset;
    }

.benefits-wrapper > :first-child {
    margin-left: 0;
}

.benefits-wrapper > :last-child {
    margin-right: 0;
}

.benefits-wrapper {
    max-height: 70vh;
    flex-direction: column;
  overflow-y: scroll;
  mask-image: none;
}

.benefits-wrapper > *:not(:last-child) {
    margin-bottom: 2rem;
}
}

.slick-next::before, .slick-prev::before {
  content: none;
}

`

export const PrevArrow = styled.div`
position: absolute;
top: 16rem;
left: 90%;
img{
    width: 20px;
    height: 20px;
    background: rgb(var(--rgb-theme));
    border-radius: 50%;
    transform: rotate(180deg);
    padding: 0.2rem;
}

@media(max-width: 1200px){
left: 85%;
}

@media(max-width: 800px){
left: 80%;
}
`

export const NextArrow = styled.div`
position: absolute;
top: 16rem;
right: 3rem;
img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0.2rem;
    background: rgb(var(--rgb-theme));
    /* transform: rotate(270deg); */
}
`