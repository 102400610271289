import styled from "styled-components";

export const Container = styled.section`

display: flex;
align-items: center;
justify-content: space-between;
padding: 20px 80px;
border-bottom: 1px solid rgb(232, 232, 232);
position: sticky;
top: 0;
background-color: rgb(var(--rgb-white));
z-index: 2;

.header-logo{
    height: 30px;
}

.header-nav-wrapper{
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
}

.nav-link{
    text-decoration: none;
}

.nav-text{
    font-size: 1rem;
    color: rgb(var(--rgb-nero-black));
}

.login-actions{
    display: flex;
    align-items: center;
    gap: 24px;
}

.sign-in{
    font-size: 16px;
    font-weight: 500;
}

.register{
    width: 109px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(var(--rgb-white));
    border: none;
    background-color: rgb(var(--rgb-theme));
    border-radius: 100px;
}

@media(max-width: 1200px){
padding: 15px 40px;
}

@media(max-width: 500px){
padding: 10px 20px;

.header-logo{
    height: 25px;
}

.register{
    width: 66px;
    height: 26px;
    font-size: 10px;
}
}
`