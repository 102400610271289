import React, { useState } from 'react';
import { Container } from './styles';

const ModalOverlay = ({ isOpen, children }) => {

  return (
    <Container>
      <div className={`overlay ${isOpen ? 'open' : ''}`}>
            {children}
      </div>
    </Container>
  );
};

export default ModalOverlay;