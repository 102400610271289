import { useState, useEffect } from "react";
 
export const useResize = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
 
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
 
    window.addEventListener('resize', handleResize);
 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  return {screenWidth};
}