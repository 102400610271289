import styled from "styled-components";

export const Container = styled.section`
background: rgb(var(--rgb-smoke-white));
padding: 2rem 0;

p{
    font-size: 1.2rem;
    line-height: 2;
}

.container-wrapper{
.content-wrapper{
    display: flex;
    justify-content: space-between;
.title-class{
    width: 50%;
    align-items: flex-start;
    text-align: left;
}

p{
    width: 50%;
}
}

.img-wrapper{
    display: flex;
    gap: 1rem;
    padding-top: 2rem;
    .img-one{
        width: 50%;
    }
    .bottom-img{
        width: 50%;
        display: flex;
        gap: 1rem;
    .img-two, .img-three{
        width: 50%;
    }
}
}
}

@media(max-width: 809px){

.title-wrapper{
    margin-bottom: 2rem;
}
    .container-wrapper{
    .img-wrapper{
        gap: 6px;
        
        .bottom-img{
            gap: 6px;
        }
    }
}
}

@media(max-width: 500px){
    padding-left: 1rem;
    padding-right: 1rem;
p{
    font-size: 1rem;
}
}
`