import { Container } from "./styles";
import Title from "../../../components/title";
import { Slide } from "react-awesome-reveal";

const Vision = () => {
  return (
    <Container>
      <Title context={"Vision"}wrapperClass={"vision-title"}/>
      <Slide direction="up" triggerOnce>
      <p>Leading the charge towards a future where organizations navigate complexities with confidence</p>
      </Slide>
    </Container>
  )
}

export default Vision
