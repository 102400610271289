import styled from "styled-components";

export const Container = styled.section`
width: 50%;
display: flex;
flex-direction: column;
gap: 2rem;
background: rgb(var(--rgb-white));
padding: 2rem;
border-radius: 2rem;

.mission-title{
    align-items: flex-start;
    text-align: left;
    margin: 0;
}

.mission-content{
    line-height: 2;
    font-size: 1.2rem;
}

@media(max-width: 700px){
width: unset;
background: transparent;
}

@media(max-width: 500px){
padding: 1rem;

.mission-content{
    font-size: 1rem;
}
}
`