import { Container } from "./styles";
import Title from "../../../components/title";
import { Slide } from "react-awesome-reveal";
import { motion } from "framer-motion";
import img_about_us_1 from "../../../assets/images/img_about_us_1.webp"
import img_about_us_2 from "../../../assets/images/img_about_us_2.webp"
import img_about_us_3 from "../../../assets/images/img_about_us_3.webp"
import { useResize } from "../../../hooks/useResize";

const AboutUs = () => {
  const {screenWidth} = useResize()
  console.log(screenWidth, "screenWidth");
  const renderAboutUsContent =()=>(
<p>
        <Slide direction="right" triggerOnce>
          At Citadelvue, is dedicated to transforming GRC management for
          organizations., Enabling businesses to navigate regulatory compliance
          and risk management effortlessly. With its intuitive interface and
          powerful features, our product streamlines GRC processes, boosting
          organizational efficiency. From tracking compliance to assessing risks
          and generating reports, our solution equips you with everything needed
          for effective compliance and risk mitigation.
        </Slide>
        </p>
  )
  return (
    <Container>
        <div className="indentation container-wrapper">
      <div className="content-wrapper">
        <Title context={"About Us"} wrapperClass={"title-wrapper"} />
        {screenWidth > 809 && renderAboutUsContent()}
      </div>
        {screenWidth <= 809 && renderAboutUsContent()}
        <Slide direction="up" triggerOnce>
      <div className="img-wrapper">
        <img src={img_about_us_1} className="img-one" alt=""/>
        <div className="bottom-img">
        <img src={img_about_us_2} className="img-two" alt=""/>
        <img src={img_about_us_3} className="img-three" alt=""/>
        </div>
      </div>
      </Slide>
      </div>
    </Container>
  );
};

export default AboutUs;
