import { Container } from "./styles";
import Title from "../../../components/title";
import { Slide } from "react-awesome-reveal";

const arr = [
  { 
    key: "Comprehensive Solutions",
    text: "Our product offers a comprehensive suite of features to address all your Governance, Risk, and Compliance (GRC) needs in one platform.",
  },
  { 
    key: "User-Friendly Interface",
    text: "With an intuitive design and user-friendly interface, our product is easy to navigate and accessible to users at all levels of expertise.",
  },
  { 
    key: "Cutting-Edge Technology",
    text: "Built on the latest technology stack, our product delivers top-notch performance, security, and reliability to meet the demands of today's business environment.",
  },
  { 
    key: "Exceptional Support",
    text: "Our dedicated support team is available to assist you every step of the way, ensuring a seamless experience and prompt resolution of any issues or concerns.",
  },
  { 
    key: "Proven Track Record",
    text: "With a proven track record of success and satisfied customers, you can trust our product to deliver results and drive value for your organization.",
  },
];

const WhyUs = () => {
  const renderCards = ({key, text}) => (
    <li>
      <p><span>{key}: </span>{text}</p>
    </li>
  );
  return (
    <Container>
      <div className="indentation">
        <Title
          context={"Why Choose Our Product"}
          wrapperClass={"title-class"}
        />
        <ul>
          <Slide direction="right" triggerOnce>
            {arr?.map(renderCards)}
          </Slide>
        </ul>
      </div>
    </Container>
  );
};

export default WhyUs;
