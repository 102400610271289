import { Container } from "./styles";
import { Fade, Slide } from "react-awesome-reveal";
import img_trial_section from "../../../assets/icons/img_trial_section.svg"
import citadel_vue_logo from "../../../assets/icons/citadel_vue_logo.svg"
// import clients_images from "../../../assets/images/clients_images.png"
// import { trialBrands } from "../constants"
// import Carousal from "../../../components/carousel"
const Trial = () => {
  const caurosalSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 2500,
    cssEase: "linear",
    pauseOnHover: false,
    centerMode: true,
    varialeWidth: true,
  };

  // const renderBrands =(item)=>(
  //   <img src={item} className="brand" alt=""/>
  // )

  return (
    <Slide direction="left" triggerOnce>
      <Container className="indentation">
        <div className="verbiage-section">
            <p className="title-wrapper">
          <Fade duration={3000} triggerOnce>
            <p>
            Build Your Organization's<span> Resilience and Thrive</span>
              </p>
          </Fade>
            </p>
          <div className="demo-wrapper">
            <img src={img_trial_section} alt="img_trial_section" />
            <p><img src={citadel_vue_logo} alt="" /> provides integrated approach to navigate complex landscape of Governance, Risk Management and Compliance Management</p>
            <div className="clients-section">
              {/* <div className="button-wrapper"> */}
                {/* <button className="demo-button">View demo</button> */}
                {/* <button className="trial-button" onClick={scrollToBottom}>
                  Contact us
                </button>
              </div> */}
              {/* <div className="clients-wrapper">
            <img src={clients_images} alt="clients images"/>
            <div className="verbiage-wrapper">
              <h3>100K+</h3>
              <span>Clients Active</span>
            </div>
          </div> */}
            </div>
          </div>
        </div>
        {/* <div className="brands-wrapper">
      <Carousal customSetting={caurosalSettings}>
        {trialBrands.map(renderBrands)}
      </Carousal>
      </div> */}
      </Container>
    </Slide>
  );
};

export default Trial;
