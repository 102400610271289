import { headerNavItems } from "./constants"
import { Container } from "./styles"
import citadel_vue_logo from "../../assets/icons/citadel_vue_logo.svg"
import ModalOverlay from "../overlay"
import { useState } from "react"

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)
  
    const toggleOverlay=()=> setIsOpen(!isOpen)
    
    const scrollToBottom = () => {
        const element = document.querySelector('.bottom-element');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    

    const renderHeaderItems =({name, path})=>(
        <li>
            <a href={path} className="nav-link">
            <span className="nav-text">{name}</span>
            </a>
        </li>
    )

  return (
    <Container>
        <img src={citadel_vue_logo} className="pointer header-logo" alt="CitadelVue logo"/>
        <ul className="header-nav-wrapper">
            {headerNavItems?.map(renderHeaderItems)}
        </ul>
        <div className="login-actions">
        {/* <span className="pointer sign-in">Sign In</span> */}
        <button className="pointer register" onClick={scrollToBottom}>Contact us</button>
        </div>
    </Container>
  )
}

export default Header
