import styled from "styled-components";

export const Container = styled.section`
  /* background: rgb(var(--rgb-smoke-white)); */
  padding: 80px 140px;

  .content-wrapper {
    max-width: 60%;
    background: rgb(var(--rgb-white));
    padding: 64px 120px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 5px #bfbfbf, -3px -3px 5px #ffffff;

    .input-section {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgb(var(--rgb-smoke-white));
      padding: 1rem;
      border-radius: 8px;
      margin-top: 3rem;

      input {
        width: 90%;
        height: 100%;
        font-size: 16px;
        background: transparent;
        border: none;
        &:focus {
          outline: 0;
        }
        &::placeholder {
          color: rgb(var(--rgb-light-grey));
        }
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .title-class {
    max-width: 100%;
    text-align: left;
  }

  @media (max-width: 1200px) {
    padding-left: 40px;
    padding-right: 40px;
    .content-wrapper {
      max-width: 94%;
      padding: 64px 40px;
    }
  }

  @media (max-width: 809px) {
    padding-left: 24px;
    padding-right: 24px;
    .content-wrapper {
      max-width: 94%;
      padding: 32px 24px;
      border-radius: 16px;
    }
  }
`;
