import { Container } from "./styles";
import { Slide, Fade } from "react-awesome-reveal";

const Title = ({title, context, description, wrapperClass}) => {
  const applyAnimation = context?.toLowerCase().includes("vision") || context?.toLowerCase().includes("mission") || context?.toLowerCase().includes("newsletter")
  return(
    <Fade duration={1500} triggerOnce>
    <Container className={wrapperClass}>
      {title && <h3>{title}</h3>}
      {context && <h1>{context}</h1>}
      {description && <span>{description}</span>}
    </Container>
    </Fade>
  )}

export default Title
