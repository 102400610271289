import styled from "styled-components";

export const Container = styled.section`
background: rgb(var(--rgb-nero-black));
padding: 2rem;

.footer-content-wrapper{
display: flex;
justify-content: space-between;
align-items: center;

.footer-logo{
  width: 40%;
}

.contact-wrapper{
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.contact-links{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.social-media-headline{
  color: rgba(var(--rgb-white), var(--alpha-seven));
}

.contact-detail{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

.link-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
  padding: 18px;
  border-radius: 50%;
  background: rgb(var(--rgb-dark-grey));
    width: 20px;
    height: 20px;
  }
  p{
    color: rgba(var(--rgb-white), var(--alpha-seven));
  }
}
    .branch-address {
      width: 40%;
      p {
        color: rgba(var(--rgb-white), var(--alpha-seven));
      }
      & > .hq-address:first-child {
    margin-bottom: 10px;
}
      .hq-address > p:first-child {
    margin-bottom: 10px;
}
    }
}

.social-media {
  display: flex;
  gap: 16px;
  padding: 2rem 0;

.social-media-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  border-radius: 50%;
  background: rgb(var(--rgb-dark-grey));
  img {
    width: 20px;
    height: 20px;
  }
}
}

.copyright-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding-top: 24px;
  border-top: 1px solid rgb(var(--rgb-smoke-white));

  img {
    width: 16px;
    height: 16px;
  }

  .firm-name {
    color: rgb(var(--rgb-white));
    margin: 0;
    a{
      text-decoration: none;
      color: rgb(var(--rgb-theme));
    }
  }
}

@media(max-width: 1100px){
  /* padding: 0 4rem; */
  padding-left: 4rem;
  padding-right: 4rem;
  .footer-content-wrapper{
    flex-direction: column;

.contact-wrapper{
  width: 100%;
  padding-top: 2rem;
  /* justify-content: space-evenly; */
}
  }
}

@media(max-width: 690px){
  padding-left: 2rem;
  padding-right: 2rem;
  .contact-wrapper{
    font-size: 12px;
  }

  .footer-content-wrapper{
    .link-item{
      img{
        width: 16px;
        height: 16px;
        padding: 10px;
      }
    }
  }

  .social-media{
    padding: 0;
    margin-top: 8px;

    .social-media-item{
      padding: 12px;
    }
  }
}

@media(max-width: 470px){
  padding: 1rem;
  .footer-content-wrapper{
  .contact-wrapper{
    flex-direction: column-reverse;
    align-items: flex-start;
    padding-top: 1rem;

    .contact-detail{
      flex-direction: row-reverse;

      .link-item{
        gap: 0.5rem;
      }
    }

    .branch-address{
      width: unset;
      display: flex;
      gap: 1rem;
    }
  }
}

.copyright-section{
  font-size: 10px;
}
}
`;
